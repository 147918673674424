@import "./../../../styles/colors.scss";

.input {
	background-color: var(--gray-500);
	border-radius: 8px;
	width: 100%;
	padding: 16px;
	line-height: 1.4;
	color: #fff;
	border: 1px solid var(--gray-600);
	position: relative;
	&::placeholder {
		color: var(--gray-300);
		font-size: 16px;
	}
	&:focus {
		outline: 1px solid var(--purple-dark);
	}
}
