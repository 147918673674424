@import "./../../styles/colors.scss";

.header {
	display: flex;
	align-items: center;
	text-align: center;
	position: relative;
	z-index: 2;
	height: 100%;
	min-height: 200px;
	background-color: var(--gray-700);
}
