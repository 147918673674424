@import "./../../styles/colors.scss";

.todo_item {
	display: inline-flex;
	align-items: flex-start;
	width: 100%;
	gap: 12px;
	position: relative;
	background-color: var(--gray-500);
	color: #fff;
	padding: 16px;
	border-radius: 8px;
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
	border: 1px solid var(--Gray-400);
	&__checkbox {
		position: relative;
		width: 24px;
		height: 24px;
		z-index: 1;
		opacity: 0;
		cursor: pointer;
		@media (any-hover: hover) {
			&:hover {
				~ .todo_item__checkmark {
					border: 2px solid var(--blue-dark);
					background-color: rgba(31, 111, 158, 0.2);
				}
				&:checked {
					~ .todo_item__checkmark {
						background-color: var(--purple);
					}
				}
			}
		}
		&:checked {
			~ .todo_item__checkmark {
				background-color: var(--purple-dark);
				border: none;
				&::before {
					opacity: 1;
				}
			}
		}
	}
	&__checkmark_container {
		display: inline-flex;
	}
	&__checkmark {
		position: absolute;
		top: 19.5px;
		left: 19.5px;
		width: 17px;
		height: 17px;
		border-radius: 50%;
		border: 2px solid var(--blue);
		&::before {
			position: absolute;
			opacity: 0;
			top: 6px;
			left: 5px;
			content: "";
			width: 7px;
			height: 5px;
			background: url("./../../img/icons/checked.svg") transparent no-repeat
				0 0 / cover;
		}
	}
	&__label {
		line-height: 1.5;
		flex-grow: 1;
	}
}
